

























































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'

@Component
export default class Service extends Mixins(Methods) {
	appList: AppObj[] = [ 
		{
			title: '商城购物'
		},
		{
			title: '物联网'
		},
		{
			title: '智能硬件'
		},
		{
			title: '社交娱乐'
		},
		{
			title: '在线直播'
		},
		{
			title: '企业办公'
		},
		{
			title: '金融软件'
		},
		{
			title: '能源物流'
		},
		{
			title: '智能制造'
		}
	]
}
interface AppObj {
    title: string;
}
